import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import 'rxjs/Rx';
import 'rxjs-compat/add/operator/catch';

@Injectable({
    providedIn: 'root'
})

export class ApiService {
    public portalRefresh: EventEmitter<any> =  new EventEmitter();
    baseUrl = environment.baseUrl;

    User = new BehaviorSubject(null);
    user = this.User.asObservable();

    constructor(private http: HttpClient, public router: Router, public toast: ToastrService) {

    }

    getAppJsonHeader() {
        let reqHeader = new HttpHeaders({'Content-Type': 'application/json'});
        return {
            headers: reqHeader
        };
    }

    getTokenHeader(token?) {
        if (!token) {
            let t = JSON.parse(localStorage.getItem('userToken'));
            token = t.userAuth;
        }
        let reqHeader = new HttpHeaders({'Content-Type': 'application/json', Authorization: 'Bearer ' + token});
        return {
            headers: reqHeader
        };
    }

    getAcccessTokens() {
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Basic dXNlckxvZ2luOg=='
        });
        return {
            headers: headers
        };
    }

    businessData(): Observable<any> {
        return this.http.get(this.baseUrl + 'client/businessTypes', this.getAppJsonHeader());
    }

    static get SyncLocalStorage() {
        return JSON.parse(localStorage.getItem('userToken'));
    }

    public isAuthenticated(): boolean {
        if (ApiService.SyncLocalStorage) {
            this.User.next(ApiService.SyncLocalStorage);
            return true;
        }
        return false;
    }

    searchClients(data): Observable<any> {
        return this.http.post(this.baseUrl + 'user/searchClients', data, this.getAppJsonHeader());
    }

    getAvailableClientAppointment(data): Observable<any> {
        return this.http.post(this.baseUrl + 'appoint/client/available', data, this.getAppJsonHeader());
    }

    getOpenClientAppointment(data): Observable<any> {
        return this.http.get(this.baseUrl + `appoint/client/open/${data.clientId}/${data.dayOfMonth}/${data.month}/${data.year}`, this.getAppJsonHeader());
    }

    getClientService(clientId): Observable<any> {
        return this.http.get(this.baseUrl + `client/services/${clientId}`);
    }

    sendOtpRequest(data): Observable<any> {
        return this.http.put(this.baseUrl + 'user/sendOTP', data);
    }

    validateOtpRequest(data): Observable<any> {
        return this.http.post(this.baseUrl + 'user/validateOTP', data);
    }

    verifyUserToken(token): Observable<any> {
        return this.http.get(this.baseUrl + `user/validate/oAuth`, this.getTokenHeader(token)).catch(x => this.handleAuthError(x));
    }


    getWebDesc(data): Observable<any> {
        return this.http.get(this.baseUrl + 'client/webSiteAndDesc/' + data, this.getAppJsonHeader()).catch(x => this.handleAuthError(x));
    }

    verifyUserOnLoad(token): Observable<any> {
        return this.http.get(this.baseUrl + `user/dashboard/onload`, this.getTokenHeader(token)).catch(x => this.handleAuthError(x));
    }

    addAppointment(data, token): Observable<any> {
        return this.http.post(this.baseUrl + 'appoint/add', data).catch(x => this.handleAuthError(x));
    }

    mergeToken(data, token): Observable<any> {
        return this.http.post(this.baseUrl + 'token/associateToken', data, this.getTokenHeader(token)).catch(x => this.handleAuthError(x));
    }

    getActiveTokens(token): Observable<any> {
        return this.http.get(this.baseUrl + 'user/checking/validate', this.getTokenHeader(token)).catch(x => this.handleAuthError(x));
    }

    redirectToGMap(address) {
        let url = address.lat + ',' + address.lon;
        window.open('https://www.google.com/maps/search/?api=1&query=' + url);
    }

    addECheckIn(data, token, isOtp): Observable<any> {
        console.log(token);
        let url = token ? 'nextToken' : 'noOTPToken';
        return this.http.put(this.baseUrl + 'token/' + url, data, token ? this.getTokenHeader(token) : this.getAppJsonHeader()).catch(x => this.handleAuthError(x));
    }

    registerUser(data): Observable<any> {
        return this.http.put(this.baseUrl + 'user/mobile/register', data);
    }

    changePassword(data, token): Observable<any> {
        return this.http.put(this.baseUrl + 'user/changepassword', data, this.getTokenHeader(token)).catch(x => this.handleAuthError(x));
    }

    loginWithPass(data): Observable<any> {
        const requestBody = `grant_type=password&username=${data.username}&password=${data.password}`;
        return this.http.post(this.baseUrl + 'oauth/token', requestBody, this.getAcccessTokens());
    }

    setNotificationSetting(data): Observable<any> {
        return this.http.post(this.baseUrl + 'user/mobileUserNotifications', data, this.getTokenHeader());
    }

    getAttached(data) {
        return this.http.post(this.baseUrl + 'client/getAttachData', data, this.getAppJsonHeader()).catch(x => this.handleAuthError(x));
    }

    getLogoData(data) {
        return this.http.get(this.baseUrl + `client/getLogoData/${data.clientId}`, this.getAppJsonHeader()).catch(x => this.handleAuthError(x));
    }

    getUserDetail(token): Observable<any> {
        return this.http.get(this.baseUrl + 'user/details', this.getTokenHeader(token)).catch(x => this.handleAuthError(x));
    }

    getLang(): Observable<any> {
        return this.http.get(this.baseUrl + 'services/supported/languages', this.getAppJsonHeader()).catch(x => this.handleAuthError(x));
    }

    getLangData(code): Observable<any> {
        return this.http.get(this.baseUrl + 'services/language/user/' + code, this.getAppJsonHeader()).catch(x => this.handleAuthError(x));
    }

    checkActiveToken(id, token): Observable<any> {
        return this.http.get(this.baseUrl + 'user/booking/validate/' + id, this.getTokenHeader(token)).catch(x => this.handleAuthError(x));
    }

    getAllPortalDetails(id, mobilePortal?: boolean): Observable<any> {
        const ApiURL = this.baseUrl + 'client' + (mobilePortal ? '/mobilePortal/' : '/businessPortal/') + id;
        return this.http.get(ApiURL, this.getAppJsonHeader()).catch(x => this.handleAuthError(x));
    }


    cancelAppointment(data): Observable<any> {
        return this.http.post(this.baseUrl + 'appoint/delete', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    getHours(clientId): Observable<any> {
        return this.http.get(this.baseUrl + 'client/hours/' + clientId).catch(x => this.handleAuthError(x));
    }

    cancelToken(data): Observable<any> {
        return this.http.post(this.baseUrl + 'token/cancelToken', data, this.getAppJsonHeader()).catch(x => this.handleAuthError(x));
    }

    getTokenStatus(data): Observable<any> {
        return this.http.post(this.baseUrl + 'token/lightTokenStatus', data, this.getAppJsonHeader()).catch(x => this.handleAuthError(x));
    }

    getTokenStatusDetail(data): Observable<any> {
        return this.http.post(this.baseUrl + 'token/detailTokenStatus', data, this.getAppJsonHeader()).catch(x => this.handleAuthError(x));
    }

    getCloseStatus(id): Observable<any> {
        return this.http.get(this.baseUrl + 'user/closeStatus/' + id).catch(x => this.handleAuthError(x));
    }

    getAllUserDetail(): Observable<any> {
        let t = JSON.parse(localStorage.getItem('userToken'));
        return this.http.get(this.baseUrl + 'user/mobileUserProfile', this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }


    getClientTime(id): Observable<any> {
        return this.http.get(this.baseUrl + 'user/client/waitTime/' + id).catch(x => this.handleAuthError(x));
    }

    deleteUser(): Observable<any> {
        return this.http.delete(this.baseUrl + 'user/delete', this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    editProfile(data, token?): Observable<any> {
        return this.http.post(this.baseUrl + 'user/mobileUserProfile', data, this.getTokenHeader(token)).catch(x => this.handleAuthError(x));
    }

    shortTokenStatus(data): Observable<any> {
        return this.http.post(this.baseUrl + 'token/shortTokenStatus', data).catch(x => this.handleAuthError(x));
    }

    shortLightTokenStatus(data): Observable<any> {
        return this.http.post(this.baseUrl + 'token/shortLightTokenStatus', data).catch(x => this.handleAuthError(x));
    }

    shortCancelToken(data): Observable<any> {
        return this.http.post(this.baseUrl + 'token/shortCancelToken', data).catch(x => this.handleAuthError(x));
    }

    appointmentStatus(id): Observable<any> {
        return this.http.get(this.baseUrl + 'appoint/id/' + id).catch(x => this.handleAuthError(x));
    }
    
    appointmentStatusTokenDetails(id) {
        return this.http.get(this.baseUrl + 'appoint/at/id/' + id).catch(x => this.handleAuthError(x));
    } 

    deleteAppointmentStatus(id): Observable<any> {
        return this.http.delete(this.baseUrl + 'appoint/user/cancel/' + id).catch(x => this.handleAuthError(x));
    }

    checkInInfoForIntegrate(clientId: string): Observable<any> {
        return this.http.get(`${this.baseUrl}client/integrate/checkin/${clientId}`).catch(x => this.handleAuthError(x));
    }

    bookingInfoForIntegrate(clientId: string, day: number, month: number, year: number): Observable<any> {
        return this.http.get(`${this.baseUrl}client/integrate/appoint/${clientId}/${day}/${month}/${year}`, this.getAppJsonHeader());
    }

    
    bookFirstAvailableAppointment(data): Observable<any> {
        return this.http.post(this.baseUrl + 'appoint/client/day/next/fetch', data).catch(x => this.handleAuthError(x));
    }


    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401) {
            this.User.next(null);
            localStorage.removeItem('userToken');
            this.router.navigate(['/']);
        } else {
            return Observable.throw(err.error);
        }
    }


}
